import React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Awards from "../components/Awards";

const AwardsPage = () => {
  return (
    <Layout pageTitle="KWEC | Awards">
      <Navbar />
      <PageHeader title="Awards" />
      <Awards />
      <Footer />
    </Layout>
  );
};

export default AwardsPage;
