import image1 from "../assets/images/awards/image1.jpg";
import image2 from "../assets/images/awards/image2.jpg";
import image3 from "../assets/images/awards/image3.jpg";
import image4 from "../assets/images/awards/image4.jpg";
import image5 from "../assets/images/awards/image5.jpg";
import image6 from "../assets/images/awards/image6.jpg";
import image7 from "../assets/images/awards/image7.jpg";
import image8 from "../assets/images/awards/image8.jpg";
import image9 from "../assets/images/awards/image9.jpg";
import image10 from "../assets/images/awards/image10.jpg";
import image11 from "../assets/images/awards/image11.jpg";

export const awardphotos = [
  {
    src: image1,
    width: 4,
    height: 3,
  },
  {
    src: image5,
    width: 3,
    height: 4,
  },
  {
    src: image4,
    width: 4,
    height: 3,
  },

  {
    src: image11,
    width: 4,
    height: 3,
  },
  {
    src: image3,
    width: 1,
    height: 1,
  },
  {
    src: image8,
    width: 3,
    height: 4,
  },
  {
    src: image10,
    width: 1,
    height: 1,
  },
  {
    src: image7,
    width: 3,
    height: 4,
  },

  {
    src: image6,
    width: 1,
    height: 1,
  },

  {
    src: image9,
    width: 4,
    height: 3,
  },
];
